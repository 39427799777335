<template>
  <div class="">
    <canvas ref="canvasFront" :width="screenWidth" :height="screenWidth / 1.58"></canvas>
    <canvas ref="canvasBack" :width="screenWidth" :height="screenWidth / 1.58"></canvas>
  </div>
</template>

<script>
import frontBgImage from '@/assets/credential/front.jpg'
import backBgImage from '@/assets/credential/back.jpg'
export default {
  data() {
    return {
      screenWidth: window.innerWidth,
      frontContent: {
        QRCode: 'https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1720093087185.jpg',
        Name: '张洋',
        DutiesDesc: '队长、副主任、副部长',
        TeamNames: '固始县红十字蓝天救援队',
        Code: '豫1234567890',
        // HeadImg: 'https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1720093616832.png',
        HeadImg: 'https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1720093087185.jpg',
        // HeadImg: '',
        IdCard: '413026********00312',
        Passport: '无',
        BloodDesc: 'A',
        Allergy: '无',
        TouchMan: '李晓红',
        TouchTel: '15512345678',
      },
      backContent: null,
    }
  },
  mounted() {
    console.log(JSON.parse(this.$route.params.obj))
    this.frontContent = JSON.parse(this.$route.params.obj)
    if (this.frontContent.HeadImg) {
      this.frontContent.HeadImg = this.frontContent.HeadImg.replace(/\*/g, '/')
    }
    if (this.frontContent.QRCode) {
      this.frontContent.QRCode = this.frontContent.QRCode.replace(/\*/g, '/')
    }
    if (!this.frontContent.Name) {
      this.frontContent.Name = ''
    }
    if (!this.frontContent.DutiesDesc) {
      this.frontContent.DutiesDesc = ''
    }
    if (!this.frontContent.TeamNames) {
      this.frontContent.TeamNames = ''
    }
    if (!this.frontContent.Code) {
      this.frontContent.Code = ''
    }
    if (!this.frontContent.IdCard) {
      this.frontContent.IdCard = ''
    }
    if (!this.frontContent.Passport) {
      this.frontContent.Passport = ''
    }
    if (!this.frontContent.BloodDesc) {
      this.frontContent.BloodDesc = ''
    }
    if (!this.frontContent.Allergy) {
      this.frontContent.Allergy = ''
    }
    if (!this.frontContent.TouchMan) {
      this.frontContent.TouchMan = ''
    }
    if (!this.frontContent.TouchTel) {
      this.frontContent.TouchTel = ''
    }
    this.drawCanvas()
    this.drawCanvasBack()
  },
  methods: {
    //  正面
    drawCanvas() {
      const canvas = this.$refs.canvasFront
      const ctx = canvas.getContext('2d')
      // 加载背景图
      const backgroundImage = new Image()
      backgroundImage.src = frontBgImage

      // 加载要插入的图片
      const insertedImage = new Image()
      const insertedImage1 = new Image()
      if (this.frontContent.HeadImg != '' && this.frontContent.HeadImg != null) {
        insertedImage.src = this.frontContent.HeadImg
      }
      if (this.frontContent.QRCode != '' && this.frontContent.QRCode != null) {
        insertedImage1.src = this.frontContent.QRCode
      }
      Promise.all([
        new Promise((resolve) => {
          backgroundImage.onload = () => resolve()
        }),
        // 使用三元运算符来决定是否包含第二个Promise
        this.frontContent.HeadImg && this.frontContent.HeadImg !== ''
          ? new Promise((resolve) => {
              insertedImage.onload = () => resolve()
              // 同样处理onerror事件
              insertedImage.onerror = () => {
                // 错误处理逻辑
                console.error('插入的图片加载失败')
                resolve() // 或者拒绝这个Promise，取决于您的需求
              }
              // 确保触发加载，如果图片已经在缓存中
              if (insertedImage.complete) {
                resolve()
              }

              insertedImage1.onload = () => resolve()
              // 同样处理onerror事件
              insertedImage1.onerror = () => {
                // 错误处理逻辑
                console.error('插入的图片加载失败')
                resolve() // 或者拒绝这个Promise，取决于您的需求
              }
              // 确保触发加载，如果图片已经在缓存中
              if (insertedImage1.complete) {
                resolve()
              }
            })
          : // 如果条件不满足，返回一个已经解决的Promise
            Promise.resolve(),
      ]).then(() => {
        // 计算画布和背景图的宽高比
        const canvasAspectRatio = canvas.width / canvas.height
        const imageAspectRatio = backgroundImage.width / backgroundImage.height
        let renderableWidth, renderableHeight

        // 如果画布的宽高比大于背景图的宽高比，则背景图的宽度将占满画布，高度根据比例缩放
        if (canvasAspectRatio > imageAspectRatio) {
          renderableHeight = canvas.height
          renderableWidth = backgroundImage.width * (renderableHeight / backgroundImage.height)
        } else {
          // 如果画布的宽高比小于背景图的宽高比，则背景图的高度将占满画布，宽度根据比例缩放
          renderableWidth = canvas.width
          renderableHeight = backgroundImage.height * (renderableWidth / backgroundImage.width)
        }

        // 清空画布并绘制背景图
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(backgroundImage, 0, 0, renderableWidth, renderableHeight)

        // 然后绘制要插入的图片到指定位置

        const imageX = canvas.width * 0.031 // 要插入图片的左上角 x 坐标
        const imageY = canvas.height * 0.3 // 要插入图片的左上角 y 坐标
        const imageWidth = canvas.width * 0.222 // 要插入图片的宽度
        const imageHeight = canvas.height * 0.453 // 要插入图片的高度
        ctx.drawImage(insertedImage, imageX, imageY, imageWidth, imageHeight)

        const imageX1 = canvas.width * 0.719 // 要插入图片的左上角 x 坐标
        const imageY1 = canvas.height * 0.4 // 要插入图片的左上角 y 坐标
        const imageWidth1 = canvas.width * 0.203 // 要插入图片的宽度
        const imageHeight1 = canvas.height * 0.32 // 要插入图片的高度
        ctx.drawImage(insertedImage, imageX, imageY, imageWidth, imageHeight)
        ctx.drawImage(insertedImage1, imageX1, imageY1, imageWidth1, imageHeight1)
        // 动态计算标题文字大小
        const titleFontSize = Math.min(renderableWidth * 0.025) // 文字大小画布宽度的3%

        const fontFamily = 'Arial'
        // 添加文字 姓名

        const text = this.frontContent.Name + ' / ' + this.frontContent.Pinyin
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(text, canvas.width / 3.42, canvas.height / 2.5)

        // 添加文字 职位
        const textPost = this.frontContent.DutiesDesc
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textPost, canvas.width / 3.42, canvas.height / 1.93)

        // 添加文字 所属队伍
        const textUnits = this.frontContent.TeamNames
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textUnits, canvas.width / 3.42, canvas.height / 1.57)

        // 添加文字 编号
        const textNo = this.frontContent.Code
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textNo, canvas.width / 3.42, canvas.height / 1.33)
      })
    },

    // 背面
    drawCanvasBack() {
      const canvas = this.$refs.canvasBack
      const ctx = canvas.getContext('2d')
      // 加载背景图
      const backgroundImage = new Image()
      backgroundImage.src = backBgImage

      // 等待背景图加载完成后绘制到画布
      backgroundImage.onload = () => {
        // 计算画布和背景图的宽高比
        const canvasAspectRatio = canvas.width / canvas.height
        const imageAspectRatio = backgroundImage.width / backgroundImage.height
        let renderableWidth, renderableHeight

        // 如果画布的宽高比大于背景图的宽高比，则背景图的宽度将占满画布，高度根据比例缩放
        if (canvasAspectRatio > imageAspectRatio) {
          renderableHeight = canvas.height
          renderableWidth = backgroundImage.width * (renderableHeight / backgroundImage.height)
        } else {
          // 如果画布的宽高比小于背景图的宽高比，则背景图的高度将占满画布，宽度根据比例缩放
          renderableWidth = canvas.width
          renderableHeight = backgroundImage.height * (renderableWidth / backgroundImage.width)
        }

        // 清空画布并绘制背景图
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(backgroundImage, 0, 0, renderableWidth, renderableHeight)

        // 动态计算标题文字大小
        const titleFontSize = Math.min(renderableWidth * 0.025) // 文字大小画布宽度的3%

        const fontFamily = 'Arial'
        // 添加文字 身份证号

        const textIdCard = this.frontContent.IdCard
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textIdCard, canvas.width / 4.9, canvas.height / 2.86)

        // 添加文字 护照号
        const textPassportNo = this.frontContent.Passport
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textPassportNo, canvas.width / 3.25, canvas.height / 2.31)

        // 添加文字 血型
        const textBloodType = this.frontContent.BloodDesc
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textBloodType, canvas.width / 3.75, canvas.height / 2.05)

        // 添加文字 药品过敏史
        const textAllergicHistory = this.frontContent.Allergy
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textAllergicHistory, canvas.width * 0.037, canvas.height / 1.71)

        // 添加文字 紧急联系人
        const textContacts = this.frontContent.TouchMan
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textContacts, canvas.width * 0.037, canvas.height / 1.46)

        // 添加文字 紧急联系人电话
        const textContactsTel = this.frontContent.TouchTel
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillText(textContactsTel, canvas.width * 0.036, canvas.height / 1.26)
      }

      // 可以在这里添加其他绘图逻辑
    },
  },
}
</script>
<style scoped></style>
